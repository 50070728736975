@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@layer base {
  body {
    @apply font-[Montserrat];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
    /* @apply hover:text-[#efea5a] */
  }
}

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all .5s;
}

nav.stroke ul li a {
  position: relative;
}
nav.stroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #efea5a;
  height: 2px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}

.card-item-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}


.button-arrow {
  
  color: #353238;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  /* position: relative; */
  display: inline-block;
  outline: none;
  overflow: hidden;
  background-color: #efea5a
}

ul.tech li {
  cursor: default;
}

ul.tech li:hover {
  color: #353238;
}

ul.tech li::before {
  content: "✓";
  /* position: absolute; */
  left: 0px;
  color: #f56a00;
}

.center {
  text-align: -webkit-center;
}